import request from "../../index";

export default {
  // Reports
  getActiveBudgetList(filter) {
    return request.get("/reports/activeBudgets", filter);
  },
  getInactiveBudgetList(filter) {
    return request.get("/reports/inactiveBudgets", filter);
  },
  getTagsWithServices(filter) {
    return request.get("/reports/tags-with-services", filter);
  },
  getHistoricalBudgetList(filter) {
    return request.get("/reports/historical", filter);
  },
  downloadHistoricalBudgetList(filter) {
    return request.post("/reports/historical/download", filter, { responseType: "arraybuffer" });
  },
  getBudgetReconciliationList(filter) {
    return request.get("/reports/reconciliation", filter);
  },
  getQuickViewList(filter) {
    return request.get("/reports/quickView", filter);
  },
  downloadQuickViewReport(filter) {
    return request.post("/reports/quickView/download", filter, { responseType: "arraybuffer" });
  },
  getActiveBudgetsByClientIds(ids) {
    return request.post("/budgets/getActiveBudgetsByClientIds", ids);
  },
  getComparisonReport(filter) {
    return request.get("/reports/comparisonReport", filter);
  },

  // Budgets
  postBudgetData(budget) {
    return request.post("/budgets", budget);
  },
  getActiveBudgetsById(filter) {
    return request.get("/budgets/getActiveBudgetsWithFees", filter);
  },
  getAllBudgetsWithParams(params) {
    return request.post("/budgets/getAllBudgetsWithParams", params);
  },
  getBudgetById(id) {
    return request.get(`/budgets/${id}`);
  },
  postBudgetWithFees(budget) {
    return request.post("/budgets/createBudgetWithFees", budget);
  },
  updateBudgetWithFees(budget, id) {
    return request.put(`/budgets/${id}/updateBudgetWithFees`, budget);
  },
  deleteBudgetWithFees(id) {
    return request.delete(`/budgets/${id}/deleteBudgetWithFees`);
  },
  getCancellations(filters) {
    return request.get("/reports/upcomingCancellations", filters);
  },
  getFCASpend(clientId, body) {
    return request.post(`/reports/fca-spend/${clientId}`, body);
  },
  confirmBudgetChange(budgetChangeId, confirmedBy) {
    return request.post("/budget_changes", { id: budgetChangeId, confirmedBy });
  },
  getBudgetChangesForUser(userId) {
    return request.get(`/budget_changes/forUser/${userId}`);
  },
  getSectionBudgetChanges(clientId, sectionId) {
    return request.get(`/section_budget_changes?clientId=${clientId}&sectionId=${sectionId}`);
  },
  confirmSectionBudgetChange(budgetChangeId, confirmedBy) {
    return request.post("/section_budget_changes", { id: budgetChangeId, confirmedBy });
  },
};
