import request from "../../index";

export default {
  getServiceListWithRecurring(clientId) {
    return request.get(`/services?feeType=Recurring&active=true&clientId=${clientId}`);
  },
  postServiceData(service) {
    return request.post("/services", service);
  },
  updateService(serviceId, service) {
    return request.put(`/services/${serviceId}`, service);
  },
  deleteServiceById(id) {
    return request.delete(`/services/${id}`);
  },
  getAllServicesOnly(filter = {}) {
    return request.get("/services/allServicesOnly", filter);
  },
  saveProfileTypes(serviceId, profileTypeIds) {
    return request.post(`/services/${serviceId}/profile-types`, { profileTypeIds });
  },
};
