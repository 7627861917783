<template>
  <section>
    <ClientBudgetFilter />
    <template v-if="loading">
      <v-skeleton-loader type="table" />
    </template>
    <template v-else>
      <v-data-table
        mobile-breakpoint="0"
        :disable-pagination="true"
        :hide-default-footer="true"
        class="clientBudget"
        :headers="headers"
        :items="x"
      >
        <template v-slot:header="{}">
          <tr class="font-weight-bold total">
            <td></td>
            <td></td>
            <td>All Total:</td>
            <td>{{ formatCurrency(totalInvoice()) }}</td>
            <td v-if="!hideActualSpend">{{ formatCurrency(totalActualSpend()) }}</td>
            <td></td>
            <td></td>
          </tr>
        </template>
        <template v-slot:item="{}">
          <template v-for="tag in itemsByTag">
            <tr class="font-weight-bold" v-bind:key="'header' + tag.title">
              <td class="parent" colspan="2">{{ tag.title }}</td>
              <td class="parent">Total {{ tag.title }}:</td>
              <td :colspan="!hideActualSpend ? '0' : '4'" class="parent">
                {{ formatCurrency(tag.totals.invoiceAmount) }}
              </td>
              <td v-if="!hideActualSpend" colspan="4" class="parent">
                {{ formatCurrency(tag.totals.actualSpend) }}
              </td>
            </tr>
            <template v-if="tag.budgets.length > 0">
              <template v-for="budget in tag.budgets">
                <tr :key="`${tag.title}${budget.id}`">
                  <td>
                    {{ budget.Service.name }}
                    <template v-if="tag.title === 'Multiple Tag Budgets'">
                      <template v-for="tag in budget.Tags">
                        <v-tooltip top v-if="tag.description" :key="tag.id">
                          <template v-slot:activator="{ on }">
                            <v-chip color="primary" small v-on="on">{{ tag.name }}</v-chip>
                          </template>
                          {{ tag.description }}
                        </v-tooltip>
                        <v-chip color="primary" v-else small :key="tag.id">{{ tag.name }}</v-chip>
                      </template>
                    </template>
                  </td>
                  <td class="fit">
                    {{ formatDate(budget.startsAt, "MMMM DD, YYYY") }}
                  </td>
                  <td v-if="budget.endsAt">
                    {{ formatDate(budget.endsAt, "MMMM DD, YYYY") }}
                  </td>
                  <td v-else>Active</td>
                  <td>
                    {{ formatCurrency(budget.invoiceAmount) }}
                  </td>
                  <td v-if="!hideActualSpend">
                    {{ formatCurrency(budget.actualSpend) }}
                  </td>
                  <td>
                    <span v-if="budget.notes">{{ budget.notes }}</span>
                  </td>
                  <td class="flex items-center">
                    <template v-if="disableEdit !== true">
                      <router-link
                        :to="{
                          name: 'budgets.edit',
                          params: { id: budget.id },
                          query: { ...filterParams },
                        }"
                      >
                        <v-btn class="mr-1" icon color="info">
                          <v-icon small>fas fa-edit</v-icon>
                        </v-btn>
                      </router-link>
                      <v-btn class="mr-1" icon color="error" @click="deleteItem(budget.id)">
                        <v-icon small>fas fa-trash</v-icon>
                      </v-btn>
                    </template>
                  </td>
                </tr>
                <tr v-for="fee in budget.fees" :key="fee.id">
                  <td>
                    {{ fee.FeeService.name }}
                    <template v-if="tag.title === 'Multiple Tag Budgets'">
                      <template v-for="tag in budget.Tags">
                        <template v-if="tag.includeFees">
                          <v-tooltip top v-if="tag.description" :key="tag.id">
                            <template v-slot:activator="{ on }">
                              <v-chip color="primary" small v-on="on">{{ tag.name }}</v-chip>
                            </template>
                            {{ tag.description }}
                          </v-tooltip>
                          <v-chip color="primary" v-else small :key="tag.id">{{ tag.name }}</v-chip>
                        </template>
                      </template>
                    </template>
                  </td>
                  <td>
                    {{ formatDate(fee.startsAt, "MMMM DD, YYYY") }}
                  </td>
                  <td v-if="fee.endsAt">
                    {{ formatDate(fee.endsAt, "MMMM DD, YYYY") }}
                  </td>
                  <td v-else>Active</td>
                  <td v-if="!hideActualSpend">{{ formatCurrency(fee.invoiceAmount) }}</td>
                  <td>
                    {{ formatCurrency(fee.invoiceAmount) }}
                  </td>
                  <td>
                    <span v-if="fee.notes">{{ fee.notes }}</span>
                  </td>
                  <td>
                    <template v-if="disableEdit !== true">
                      <router-link
                        :to="{
                          name: 'budgets.edit',
                          params: { id: budget.id },
                          query: { ...filterParams },
                        }"
                      >
                        <v-btn class="mr-1" icon color="info">
                          <v-icon small>fas fa-edit</v-icon>
                        </v-btn>
                      </router-link>
                    </template>
                  </td>
                </tr>
              </template>
            </template>
            <tr v-bind:key="'header2' + tag.title">
              <td colspan="7"></td>
            </tr>
          </template>
        </template>
      </v-data-table>
    </template>
    <template v-if="!loading && itemsByTag.length === 0">
      <v-alert type="info" class="mt-5" outlined>No budgets found.</v-alert>
    </template>
  </section>
</template>

<script>
  import _ from "lodash";

  import { EventBus } from "@/bootstrap/EventBus";

  import BudgetResource from "../../../../../api/resources/accounting/BudgetResource";
  import ClientBudgetFilter from "./ClientBudgetFilter.vue";

  export default {
    name: "ClientBudgetTable",
    components: { ClientBudgetFilter },
    props: ["items", "headers", "filterParams", "loading", "hideActualSpend", "disableEdit"],
    data() {
      return {
        /**
         * Intentionally done to prevent duplicates (RRR - 1/21/2022)
         * TODO: Find more optimal approach for displaying
         * budget categories in segments
         */
        x: [1],
      };
    },
    computed: {
      itemsByTag() {
        const items = [];
        const recurringBudgets = _.orderBy(
          this.items
            .filter((item) => item.budgetType === "Recurring" && item.Tags.length === 0)
            .concat(
              this.items
                .filter(
                  (item) =>
                    item.budgetType === "Recurring" &&
                    item.Tags.find((tag) => tag.includeFees === false)
                )
                .map((budget) => {
                  return budget.fees.map((fee) => {
                    return {
                      ...fee,
                      Service: { name: fee.FeeService.name, order: budget.Service.order },
                      id: budget.id,
                      fees: [],
                      actualSpend: fee.invoiceAmount,
                    };
                  });
                })
                .flat()
            ),
          (item) => item.Service.order
        );

        const oneTimeBudgets = _.orderBy(
          this.items
            .filter((item) => item.budgetType === "One-Time" && item.Tags.length === 0)
            .concat(
              this.items
                .filter(
                  (item) =>
                    item.budgetType === "One-Time" &&
                    item.Tags.find((tag) => tag.includeFees === false)
                )
                .map((budget) => {
                  return budget.fees.map((fee) => {
                    return {
                      ...fee,
                      Service: { name: fee.FeeService.name, order: budget.Service.order },
                      id: budget.id,
                      fees: [],
                      actualSpend: fee.invoiceAmount,
                    };
                  });
                })
                .flat()
            ),
          (item) => item.Service.order
        );

        if (recurringBudgets.length > 0) {
          items.push({
            title: "Internal Budgets",
            budgets: recurringBudgets,
            totals: this.totalTagAmounts(recurringBudgets),
          });
        }
        if (oneTimeBudgets.length > 0) {
          items.push({
            title: "One-Time Budgets",
            budgets: oneTimeBudgets,
            totals: this.totalTagAmounts(oneTimeBudgets),
          });
        }

        const taggedBudgets = {};

        this.items.forEach((budget) => {
          if (budget.Tags.length === 1) {
            const tag = budget.Tags[0];

            if (!taggedBudgets[tag.name]) {
              taggedBudgets[tag.name] = [];
            }
            taggedBudgets[tag.name].push(budget);
          }
        });

        for (const tag in taggedBudgets) {
          if (Object.prototype.hasOwnProperty.call(taggedBudgets, tag)) {
            const budgets = taggedBudgets[tag].map(({ ...budget }) => {
              if (budget.Tags.find((t) => t.includeFees === false)) {
                budget.fees = [];
              }

              return budget;
            });

            if (Object.prototype.hasOwnProperty.call(taggedBudgets, tag)) {
              items.push({
                title: `${tag} Budgets`,
                budgets,
                totals: this.totalTagAmounts(budgets),
              });
            }
          }
        }

        const multipleTagBudgets = this.items.filter((item) => item.Tags.length > 1);

        if (multipleTagBudgets.length > 0) {
          items.push({
            title: "Multiple Tag Budgets",
            budgets: multipleTagBudgets.map(({ ...budget }) => {
              if (budget.Tags.find((tag) => tag.includeFees === false)) {
                budget.fees = [];
              }

              return budget;
            }),
            totals: this.totalTagAmounts(multipleTagBudgets),
          });
        }

        return items;
      },
    },
    methods: {
      totalInvoice() {
        let total = 0;

        this.items.forEach((item) => {
          total += this.invoiceFees(item);
        });

        return Number(total).toFixed(2);
      },
      totalActualSpend() {
        let total = 0;

        this.items.forEach((budget) => {
          total += budget.actualSpend;
          budget.fees.forEach((fee) => {
            total += fee.invoiceAmount;
          });
        });

        return total;
      },
      invoiceFees(item) {
        let total = item.invoiceAmount;

        if (item.fees) {
          item.fees.forEach((fee) => {
            total += fee.invoiceAmount;
          });
        }

        return total;
      },
      totalTagAmounts(budgets) {
        let totalActualSpend = 0;
        let totalInvoice = 0;

        budgets.forEach((budget) => {
          totalActualSpend += budget.actualSpend;
          totalInvoice += budget.invoiceAmount;
          budget.fees.forEach((fee) => {
            totalActualSpend += fee.invoiceAmount;
            totalInvoice += fee.invoiceAmount;
          });
        });

        return { invoiceAmount: totalInvoice, actualSpend: totalActualSpend };
      },
      deleteItem(budgetId) {
        EventBus.$emit("delete");
        EventBus.$on("confirmation", (confirm) => {
          if (confirm) {
            BudgetResource.deleteBudgetWithFees(budgetId)
              .then(() => {
                this.showSuccessNotification("Budget has been deleted.");

                EventBus.$emit("budget-refresh");
              })
              .catch(() => {
                this.showErrorNotification("Budget could not be deleted.");
              });
          }
          EventBus.$off("confirmation");
        });
      },
    },
  };
</script>

<style>
  .clientBudget th + th {
    border-left: 1px solid #dddddd;
    font-size: 15px !important;
  }
  .clientBudget td + td {
    border-left: 1px solid #dddddd;
  }
  .clientBudget td {
    font-size: 15px !important;
  }
  .clientBudget {
    max-width: 100%;
  }
  .total td {
    height: 70px !important;
    background-color: #d3d3d3;
    padding: 10px;
  }
  .parent {
    background-color: lightblue;
  }
</style>
