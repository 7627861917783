import _ from "lodash";

export default {
  methods: {
    isFulfillment(departmentId) {
      return [2, 3, 8].includes(departmentId);
    },
    isSEO(departmentId) {
      return departmentId === 1;
    },
    isSEM(departmentId) {
      return departmentId === 2;
    },
    isSocial(departmentId) {
      return departmentId === 3;
    },
    isAccountManagement(departmentId) {
      return departmentId === 4;
    },
    isProgrammatic(departmentId) {
      return departmentId === 8;
    },
    isDepartmentManager(departmentId) {
      const currentUser = this.$store.getters["authorization/currentUser"];

      const department = currentUser?.departments?.find((d) => d.id === departmentId);

      // ID: 1 = Department Manager - ID: 6 = CSM - ID: 7 = Department Lead
      return [1, 6, 7].includes(department?.DepartmentUser?.DepartmentUserTypeId);
    },
    calculateChange(currentValue, previousValue) {
      if (currentValue === previousValue) {
        return 0;
      }

      return previousValue === 0 ? 100 : (100 * (currentValue - previousValue)) / previousValue;
    },
    sanitizeActivities(taskIQActivities) {
      const activities = taskIQActivities.map((event) => {
        let activityCaption;
        const activityDescription = event.event_name.split(".");
        const model = activityDescription[0];
        const action = activityDescription[1].replace(/(^\w{1})|(\s+\w{1})/g, (letter) =>
          letter.toUpperCase()
        );

        switch (model) {
          case "tasks":
            if (action === "Status-updated") {
              activityCaption = "Updated Task Status";
            } else if (action === "Reassigned") {
              activityCaption = `${action} Task to ${event.destination.name}`;
            } else {
              activityCaption = `${action} Task`;
            }
            break;

          case "comments":
            activityCaption = `${action === "Created" ? "Left a" : action} Comment`;
            break;

          case "attachment":
            activityCaption = `${action} Attachment`;
            break;

          case "schedules":
            activityCaption = `${action} Schedules`;
            break;

          default:
            break;
        }

        return {
          activityCaption,
          createdAt: event.created_at,
          taskText: `TaskIQ-${event.task.id}: ${event.task.title}`,
          taskId: event.task.id,
        };
      });

      return _.orderBy(activities, ["createdAt"], ["desc"]);
    },
  },
};
