import request from "../index";

export default {
  getInteractionsListByClientId(id) {
    return request.get(`/interactions/by-client/${id}`);
  },
  getInteractionsByClientsId(clientIds, startDate, endDate) {
    return request.post(`/interactions/by-clients`, { clientIds, startDate, endDate });
  },
  getInteractionsByIds(interactionIds) {
    return request.post(`/interactions/by-ids`, { interactionIds });
  },
  getInteractionForReport(id, startDate, endDate) {
    return request.get(
      `/interactions/by-client-for-report/${id}?startDate=${startDate}&endDate=${endDate}`
    );
  },
  postInteractionData(interaction) {
    return request.post("/interactions", interaction, {
      headers: { "Content-Type": "multipart/form-data" },
    });
  },
  updateInteraction(interaction) {
    return request.post(`/interactions/update`, interaction, {
      headers: { "Content-Type": "multipart/form-data" },
    });
  },
  deleteInteractionById(id) {
    return request.delete(`/interactions/${id}`);
  },
  getInteractionsActivityReport(month, excludeNonProgramClients = false) {
    return request.get(`/interactions/activity-report`, { month, excludeNonProgramClients });
  },
  getUserInteractions(userIds) {
    return request.post("/interactions/by-users", { userIds });
  },
  getUserInteractionPerformanceReport(month) {
    return request.get(`/interactions/user-performance-report?month=${month}`);
  },
  getOverwatchDashboardReport(month, startDay, endDay, divisionManagerId = null, recache = false) {
    return request.get(`/interaction-reporting/overwatch-dashboard`, {
      month,
      startDay,
      endDay,
      divisionManagerId,
      recache,
    });
  },
};
