import request from "../index";

export default {
  getAccountManagerDashboard(clients) {
    return request.post(`/dashboards/account-manager`, { clients }, {}, true);
  },
  getSemDashboard(clients, startDate, endDate, breakCache = false) {
    return request.post(
      `/dashboards/sem`,
      { clients, start_date: startDate, end_date: endDate, breakCache },
      {},
      true
    );
  },
  getSocialDashboard(clients, startDate, endDate, breakCache = false) {
    return request.post(
      "/dashboards/social",
      { clients, start_date: startDate, end_date: endDate, breakCache },
      {},
      true
    );
  },
  getSocialSegmentedMetrics(clients, startDate, endDate, breakCache = false) {
    return request.post(
      "/dashboards/social-segmented",
      { clients, start_date: startDate, end_date: endDate, breakCache },
      {},
      true
    );
  },
  getProgrammaticDisplayDashboard(clients, startDate, endDate, breakCache = false) {
    return request.post(
      "/dashboards/programmatic-display",
      { clients, start_date: startDate, end_date: endDate, breakCache },
      {},
      true
    );
  },
  getProgrammaticVideoDashboard(clients, startDate, endDate, breakCache = false) {
    return request.post(
      "/dashboards/programmatic-video",
      { clients, start_date: startDate, end_date: endDate, breakCache },
      {},
      true
    );
  },
  getSeoDashboard(clients, startDate, endDate, breakCache = false) {
    return request.post(
      `/dashboards/seo`,
      { clients, start_date: startDate, end_date: endDate, breakCache },
      {},
      true
    );
  },
};
