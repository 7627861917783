<template>
  <div>
    <p class="font-weight-bold mt-4 mb-0">
      <v-btn class="px-0" text @click="openInTaskIQ(id)">{{ title }}</v-btn>
    </p>
    <p class="font-weight-light">{{ client }}</p>
    <p class="mt-1">
      <v-chip color="primary" class="mr-4">{{ formattedPriority }}</v-chip>
      <v-chip color="info">Due {{ relativeTime(due) }}</v-chip>
    </p>
  </div>
</template>

<script>
  import taskIQMixin from "@/mixins/taskIQMixin";

  export default {
    name: "TaskListItem",
    mixins: [taskIQMixin],
    props: ["title", "client", "priority", "due", "id"],
    computed: {
      formattedPriority() {
        return this.priority;
      },
    },
  };
</script>
