export default {
  data() {
    return {
      requiredTextRule: [
        (v) => (v !== undefined && v !== null && !!String(v).trim().length) || "Field is required",
      ],
      requiredArrayRule: [(value) => value?.length > 0 || "Field is required"],
      requiredFileRule: [(file) => !!file || (file && file.size > 0) || "File is Required."],
      validJSONRule: [
        (value) => {
          try {
            return value === null || !!JSON.parse(value);
          } catch (error) {
            return "Not a valid JSON";
          }
        },
      ],
      durationTimeRule: [
        (value) =>
          (value ? /^(0[0-9]|1[0-9]|2[0-4]):[0-5][0-9]$/.test(value) : true) ||
          "Must be in HH:MM format.",
      ],
      validEmailRule: [(value) => this.validateEmails(value) || "Invalid email address"],
      excludeCurrentUserEmailRule: [
        (value) => this.excludeCurrentUserEmail(value) || "You cannot add yourself.",
      ],
      duplicateEmailRule: [
        (value) => this.checkForDuplicateEmails(value) || "Duplicate email addresses",
      ],
      passwordRules: [
        (v) => !!v || "Field is required",
        (v) =>
          (!!v && v.length >= 8 && v.length <= 32) ||
          "Password must be between 8 and 32 characters",
        (v) => {
          if (v) {
            let successRulesCount = 0;
            const errorsText = [];

            const oneUpperCaseRule = /(?=.*[A-Z])/;
            const oneLowerCaseRule = /(?=.*[a-z])/;
            const oneDigitRule = /(?=.*[0-9])/;
            const oneSpecialCharacterRule = /([^A-Za-z0-9])/;

            if (oneUpperCaseRule.test(v)) {
              successRulesCount += 1;
            } else {
              errorsText.push("one uppercase letter");
            }

            if (oneLowerCaseRule.test(v)) {
              successRulesCount += 1;
            } else {
              errorsText.push("one lowercase letter");
            }

            if (oneDigitRule.test(v)) {
              successRulesCount += 1;
            } else {
              errorsText.push("one number");
            }

            if (oneSpecialCharacterRule.test(v)) {
              successRulesCount += 1;
            } else {
              errorsText.push("one symbol");
            }

            if (successRulesCount < 3) {
              return `Password must include at least ${errorsText.join(", ")}.`;
            }

            return true;
          }

          return "Field is required";
        },
      ],
      mustBePositiveNumberRule: [
        (v) => (v !== undefined && v !== null && v > 0) || "Field must be a positive number",
      ],
      mustBeNumberRule: [
        (v) =>
          (v !== undefined && v !== null && v !== "" && !Number.isNaN(v)) ||
          "Field must be a number",
      ],
      mustBeIntegerRule: [
        (v) => {
          if (v === undefined || v === null) {
            return true;
          }

          return Number.isInteger(Number(v)) || "Field must be an integer";
        },
      ],
      validUrlRule: [(value) => !value || this.validateURL(value, false) || "Invalid URL address"],
    };
  },
  methods: {
    minCharactersRule(minCharacter, field = "Field") {
      return [
        (value) =>
          (value || "").length >= minCharacter ||
          `${field} must be ${minCharacter} characters or more.`,
      ];
    },
    maxCharactersRule(maxCharacter, field = "Field") {
      return [
        (value) =>
          (value || "").length <= maxCharacter ||
          `${field} must be ${maxCharacter} characters or less.`,
      ];
    },
    filesShouldBeLessThanMBRule(mb) {
      return [
        (files) =>
          !(
            files?.reduce((total, currentNumber) => total + currentNumber.size, 0) >
            1024 * 1024 * mb
          ) || `File size should be less than ${mb} mb.`,
      ];
    },
    fileShouldBeLessThanMBRule(mb) {
      return [
        (file) => !(file?.size > 1024 * 1024 * mb) || `File size should be less than ${mb} mb.`,
      ];
    },
    validateURL(url, returnURL = true) {
      let check = url?.trim()?.toLowerCase() || "";

      if (!check.startsWith("http://") && !check.startsWith("https://")) {
        check = `https://${check}`;
      }

      try {
        const checkedUrl = new URL(check);

        if (checkedUrl.hostname.includes(".")) {
          if (returnURL) {
            return checkedUrl;
          }

          return checkedUrl instanceof URL;
        }

        return false;
      } catch (err) {
        return false;
      }
    },
    validateEmail(email) {
      return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
    },
    validateEmails(emails) {
      let valid = true;

      if (emails !== null) {
        if (Array.isArray(emails)) {
          // eslint-disable-next-line array-callback-return
          emails.map((email) => {
            if (typeof email === "object") {
              if (!this.validateEmail(email.email)) {
                valid = false;
              }
            } else if (!this.validateEmail(email)) {
              valid = false;
            }
          });
        } else {
          valid = this.validateEmail(emails);
        }
      }

      return valid;
    },
    excludeCurrentUserEmail(emails) {
      let valid = true;

      if (emails !== null && Array.isArray(emails)) {
        valid =
          emails.filter((email) => {
            const userEmail = this.$store.getters["authorization/currentUser"].email;

            if (typeof email === "object") {
              return email.email === userEmail;
            }

            return email === userEmail;
          }).length === 0;
      }

      return valid;
    },
    checkForDuplicateEmails(emails) {
      const values = [];

      emails.forEach((value) => {
        if (typeof value === "string") {
          values.push(value);
        } else {
          values.push(value.email);
        }
      });

      const uniqueEmails = [...new Set(values)];

      return values.length === uniqueEmails.length;
    },
  },
};
