<template>
  <v-navigation-drawer
    app
    fixed
    width="260"
    mobile-breakpoint="960"
    :mini-variant="shouldExpandOnHover"
    color="primary"
    dark
    :expand-on-hover="shouldExpandOnHover"
    class="soft-shadowed-left sidebar pb-24 md:pb-0 z-[55]"
    v-model="isMobileOpen"
  >
    <v-list nav class="py-0 mt-20">
      <v-list-item-group v-model="activeLink" active-class="white--text">
        <router-link target="_blank" :to="{ name: 'taskiq.login' }" v-if="canViewTaskIQ">
          <v-list-item>
            <v-list-item-icon>
              <v-icon>fas fa-tasks</v-icon>
            </v-list-item-icon>
            <v-list-item-content>TaskIQ</v-list-item-content>
          </v-list-item>
        </router-link>
        <div v-for="(link, index) in links" :key="index">
          <template v-if="inRightAccessGroup(link) && haveNecessaryRole(link)">
            <router-link :to="{ name: link.route }" v-if="link.route">
              <v-list-item>
                <v-list-item-icon>
                  <v-icon>{{ link.icon }}</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{ link.title }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </router-link>

            <v-list-group v-if="!link.route">
              <template v-slot:activator>
                <v-list-item-icon>
                  <v-icon>{{ link.icon }}</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{ link.title }}</v-list-item-title>
                </v-list-item-content>
              </template>
              <template v-for="childLink in link.children">
                <template v-if="haveNecessaryRole(childLink)">
                  <router-link
                    v-if="
                      !childLink.href && (childLink.title === 'GeoIQ Export' ? showGeoIQ : true)
                    "
                    :key="childLink.title"
                    :to="{ name: childLink.route }"
                  >
                    <v-list-item link>
                      <v-list-item-title v-text="childLink.title"></v-list-item-title>
                    </v-list-item>
                  </router-link>

                  <a
                    v-else-if="childLink.href"
                    :key="childLink.title"
                    target="_blank"
                    :href="getHref(childLink.href)"
                  >
                    <v-list-item>
                      <v-list-item-title v-text="childLink.title"></v-list-item-title>
                    </v-list-item>
                  </a>
                </template>
              </template>
            </v-list-group>
          </template>
        </div>
      </v-list-item-group>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
  import { EventBus } from "@/bootstrap/EventBus";
  import dashboardMixin from "@/mixins/dashboardMixin";

  export default {
    name: "Sidebar",
    props: ["showGeoIQ"],
    mixins: [dashboardMixin],
    data: () => ({
      activeLink: null,
      sidebarOpen: false,
      isMobileOpen: true,
      mini: true,
      windowWidth: 1920,
      links: [
        {
          title: "Dashboard",
          icon: "fas fa-house-user",
          route: "dashboard.monthly-report",
          accessGroups: ["Employee", "Client", "Partner", "Regional Manager"],
        },
        {
          title: "Accounting",
          icon: "far fa-credit-card",
          accessGroups: ["Employee"],
          children: [
            { title: "Active Budgets", icon: "far fa-star", route: "activeBudgets.index" },
            { title: "Budget Allocations", icon: "far fa-star", route: "budgetAllocations.index" },
            { title: "Budget Comparison", icon: "far fa-star", route: "budgetComparison.index" },
            {
              title: "Budget Reconciliation",
              icon: "far fa-star",
              route: "budgetReconciliation.index",
            },
            { title: "Client View", icon: "far fa-star", route: "clientView.index" },
            { title: "Historical Budgets", icon: "far fa-star", route: "historicalBudgets.index" },
            { title: "Inactive Budgets", icon: "far fa-star", route: "inactiveBudgets.index" },
            { title: "Parent Organizations", route: "parentOrganizations.index" },
            { title: "Partners", route: "partners.index" },
            { title: "Quick View", icon: "far fa-star", route: "quickView.index" },
            { title: "Rollover Report", icon: "far fa-star", route: "reporting.rollover-report" },
            {
              title: "Upcoming Cancellations",
              icon: "far fa-dollar",
              route: "cancellations.index",
            },
          ],
        },
        {
          title: "Clients",
          icon: "far fa-address-card",
          accessGroups: ["Employee"],
          children: [
            { title: "Active Clients", route: "clients.index" },
            { title: "Contacts", route: "contacts.index" },
            { title: "Client Teams", route: "clients.clientTeam" },
            { title: "Inactive Clients", route: "clients.inactive" },
            { title: "Leads", route: "leads.index" },
            { title: "Onboarding", route: "onboarding.index" },
            { title: "Priority Watchlist", route: "priority-watchlist.index" },
            { title: "Prospects", route: "prospects.index" },
          ],
        },
        {
          title: "Reporting",
          icon: "fas fa-ad",
          accessGroups: ["Employee"],
          children: [
            { title: "Activities Report", route: "report.activities" },
            { title: "AM Report Card", route: "reporting.am-report-card" },
            { title: "Automotive Sales", route: "reporting.client_sales" },
            { title: "Bandwidth Report", route: "bandwidth.index" },
            { title: "Cancellation Report", route: "reporting.client-cancellation-report" },
            { title: "Client Login Report", route: "report.client.index" },
            {
              title: "Client Strategies",
              route: "reporting.client-strategies",
              accessRoles: ["Super Administrator", "Administrator", "Account Manager"],
            },
            { title: "Co-Op Reports", icon: "far fa-user", route: "co-op.hub" },
            {
              title: "Co-Op Report Tracker",
              icon: "far fa-dashboard",
              route: "co-op.report-tracker",
            },
            {
              title: "Email Analytics",
              icon: "far fa-chart",
              route: "report.email_analytics",
            },
            // { title: "Email Report", route: "reporting.email_report" }, this is in case zack wants this tool back eventually
            { title: "FCA Tools & Reports", icon: "far fa-chart", route: "fca.hub" },
            {
              title: "GA4 Event Manager",
              route: "reporting.ga4-event-manager",
            },
            { title: "Group Report", route: "reporting.group_report" },
            { title: "Lead Report", route: "reporting.lead_report" },
            { title: "Overwatch Dashboard", route: "reporting.overwatch-dashboard" },
            { title: "Pacing Report", icon: "far fa-user", route: "report.spend-audit-v2" },
            {
              title: "Primary Goals",
              icon: "fas fa-thumbtack",
              route: "reporting.client-primary-goals",
              accessGroups: ["Employee"],
            },
            { title: "Service Utilization", route: "reporting.service_utilization_report" },
            { title: "SEM Budget Recommendation", route: "reporting.sem-budget-recommendations" },
            { title: "SEM Configuration Report", route: "reporting.sem-configuration-report" },
            { title: "SEO Strategies", route: "reporting.seo-strategy-index" },
            { title: "Subaru Tools & Reports", icon: "far fa-chart", route: "subaru.hub" },
          ],
        },
        {
          title: "Reporting",
          icon: "fas fa-ad",
          accessGroups: ["Regional Manager"],
          key: "reporting2",
          children: [{ title: "Group Report", route: "reporting.group_report" }],
        },
        {
          title: "Tools",
          icon: "fas fa-tools",
          accessGroups: ["Employee"],
          children: [
            {
              title: "Admin Helpers",
              route: "admin.helpers",
              accessRoles: ["Super Administrator", "Administrator"],
            },
            {
              title: "Audit Logs",
              route: "admin.audit-logs",
              accessRoles: ["Super Administrator", "Administrator"],
            },
            { title: "Client Integrations", route: "tools.integrations.index" },
            {
              title: "Display & Video File Override",
              icon: "far fa-user",
              route: "dv360-override-file.index",
            },
            { title: "Forms", icon: "fas fa-tools", route: "tools.forms.index" },
            { title: "Mystery Shop Tool", href: "mysteryShop" },
            { title: "Website Migrations", route: "tools.website_migrations" },
          ],
        },
        {
          title: "Client Notes",
          icon: "fas fa-sticky-note",
          route: "tools.client_notes",
          accessGroups: ["Partner"],
        },
        {
          title: "SiteIQ",
          icon: "fas fa-globe-americas",
          accessGroups: ["Employee", "Client", "Partner"],
          children: [
            { title: "All Contacts", icon: "far fa-user", route: "siteiq.contacts" },
            { title: "GeoIQ Export", icon: "far fa-user", route: "reporting.geoiq" },
            { title: "Site Intelligence", icon: "far fa-user", route: "siteiq.intelligence" },
          ],
        },
        {
          title: "Group Performance",
          icon: "fas fa-layer-group",
          route: "reporting.group_performance",
          accessGroups: ["Employee", "Client"],
        },
        { title: "Users", icon: "fas fa-user-friends", route: "users", accessGroups: ["Employee"] },
        {
          title: "Settings",
          icon: "fa fa-cog",
          accessGroups: ["Employee"],
          children: [
            { title: "Accounting", icon: "far fa-user", route: "settings.accounting" },
            { title: "Activity Types", route: "settings.activity-types" },
            { title: "Cancellation Reasons", route: "settings.cancellation-reasons" },
            { title: "Carousel Upload", route: "carousel-upload.index" },
            { title: "Client Tags", route: "settings.client-tags" },
            {
              title: "Co-op Documents",
              route: "coop.documents",
              accessRoles: ["Super Administrator", "Administrator"],
            },
            { title: "Departments", icon: "far fa-user", route: "departments.index" },
            { title: "Groups", route: "groups.index" },
            { title: "Interaction Custom Fields", route: "custom_fields.index" },
            { title: "Interaction Types", route: "interaction_types.index" },
            {
              title: "IAM",
              route: "permission-role.index",
              accessRoles: ["Super Administrator", "Administrator"],
            },
            { title: "Industries", route: "industries.index" },
            { title: "Manufacturers", route: "manufacturers.index" },
            { title: "Overwatch Alarms", route: "overwatch_alarms.index" },
            { title: "Profit Centers", route: "profitCenters.index" },
            { title: "Regions", route: "regions.index" },
            { title: "SEO Deliverable Settings", route: "settings.seo-deliverables" },
            {
              title: "Spend Data Sources",
              route: "spend_data_sources.index",
              accessRoles: ["Developer"],
            },
            { title: "Tactics", route: "tactics.index" },
            {
              title: "Two-Factor Authentication",
              route: "two-factor-authentication.index",
              accessRoles: ["Super Administrator", "Administrator", "Developer"],
            },
            { title: "Website Providers", route: "website_provider.index" },
          ],
        },
        {
          title: "My Profile",
          icon: "fas fa-user-cog",
          route: "profile",
          accessGroups: ["Employee", "Client", "Partner", "Regional Manager"],
        },
        {
          title: "Log out",
          icon: "fas fa-power-off",
          route: "logout",
          accessGroups: ["Employee", "Client", "Partner", "Regional Manager"],
        },
      ],
    }),
    created() {
      this.registerWindowWidth();
      this.registerEvents();
      this.updateDefaultDashboardRoute();
      this.disableNavigation();
    },
    methods: {
      disableNavigation() {
        if (this.$store.getters["authorization/hasGroupAccess"] === true) {
          this.links.find((link) => link.key === "reporting2").accessGroups.push("Client");
        }
      },
      registerEvents() {
        EventBus.$on("openSidebar", () => {
          this.isMobileOpen = true;
        });
      },
      registerWindowWidth() {
        this.windowWidth = window.innerWidth;
      },
      inRightAccessGroup(link) {
        return link.accessGroups.includes(
          this.$store.getters["authorization/currentUser"].UserType.name
        );
      },
      haveNecessaryRole(link) {
        const { roles } = this.$store.getters["authorization/currentUser"];

        return (
          !link.accessRoles ||
          roles.includes("Super Administrator") ||
          roles.includes("Administrator") ||
          roles.some((role) => link.accessRoles.includes(role))
        );
      },
      getHref(href) {
        switch (href) {
          case "mysteryShop":
            return `${this.mysteryShopUrl}/auth/token/${this.$store.getters["authorization/currentUser"].kobiKey}`;
          default:
            return null;
        }
      },
      updateDefaultDashboardRoute() {
        let route = "dashboard.monthly-report";
        const currentUser = this.$store.getters["authorization/currentUser"];

        if (currentUser) {
          const departments = currentUser.departments.map((d) => d.id);

          if (departments.some(this.isFulfillment)) {
            route = "dashboard.pacing";
          }

          if (departments.some(this.isAccountManagement)) {
            route = "dashboard.account-management";
          }

          if (departments.some(this.isSEO)) {
            route = "dashboard.seo";
          }

          if (departments.some(this.isSEM)) {
            route = "dashboard.sem";
          }

          if (departments.some(this.isSocial)) {
            route = "dashboard.social";
          }
        }

        const dashboardIndex = this.links.findIndex(
          (link) => link.route === "dashboard.monthly-report"
        );

        this.links[dashboardIndex].route = route;
      },
    },
    computed: {
      shouldExpandOnHover() {
        return this.windowWidth > 1024;
      },
      mysteryShopUrl() {
        return process.env.VUE_APP_MYSTERY_SHOP_CLIENT_PROXY;
      },
      canViewTaskIQ() {
        const userType = this.$store.getters["authorization/currentUser"].UserType.name;
        const { roles } = this.$store.getters["authorization/currentUser"];

        return userType === "Employee" || (userType === "Partner" && roles.includes("Client+"));
      },
    },
  };
</script>

<style scoped>
  ::v-deep ::-webkit-scrollbar {
    width: 0;
    background: transparent;
  }
</style>
